@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Satoshi';
  src: url('./assets/fonts/Satoshi-Black.otf') format('opentype');
  font-weight: 900;
  font-style: normal;
}

html {
  padding: 0;
  margin: 0 auto;
  background-color: white;
}
body {
  font-family: 'Satoshi', sans-serif;
  color: black;
  padding: 0;
  margin: 0 auto;
}

h1 {
  font-family: 'Satoshi', sans-serif;
  font-weight: 700;
  font-size: 1.2rem;
}
h2 { 
  font-family: 'Satoshi', sans-serif;
  font-weight: 500;
  font-size: 1rem;
}
h3, label {
  font-family: 'Satoshi', sans-serif;
  font-weight: 500;
  font-size: 0.9rem;
}

@keyframes bobbing {
  0% { transform: translateY(0); }
  10% { transform: translateY(10px); }
  22% { transform: translateY(0); }
  32% { transform: translateY(6px); }
  44% { transform: translateY(0); }
  56% { transform: translateY(3px); }
  68% { transform: translateY(0); }
} 
.animate-bob1 {
  animation: bobbing 5200ms ease-in-out infinite;
}
.animate-bob2 {
  animation: bobbing 5200ms ease-in-out infinite 300ms;
}
.animate-bob3 {
  animation: bobbing 5200ms ease-in-out infinite 600ms;
}

@keyframes bell-ring {
  60% { transform: rotate(0deg); }
  70% { transform: rotate(20deg); }
  90% { transform: rotate(-20deg); }
  100% { transform: rotate(0deg); }
} 

.animate-bell {
  animation: bell-ring 5200ms ease-in-out infinite;
}

@media screen and (min-width: 640px) {
  h1 {
    font-size: 1.4rem;
  }
}